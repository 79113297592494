import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from 'reactstrap/lib/Card';

import {
  IncentivesCreativeConfigDefaultProps,
  IncentivesCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import {
  CommonAdDefaultProps,
  CommonAdPropTypes,
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { INCENTIVES_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { DfpTracking } from 'client/site-modules/shared/components/native-ad/utils/dfp-tracking';

import { AdLabel } from 'client/site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { Disclaimer } from 'client/site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { addZipCode, definePlatform } from 'site-modules/shared/components/native-ad/utils/utils';
import {
  getDisplayHeadline,
  getTrackingValue,
} from 'site-modules/shared/components/native-ad/utils/offer-incentives-ad-utils';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';

import { Styles } from './incentives-site-served-ad-styles';

import './incentives-site-served-ad.scss';

const DEFAULT_HEADLINE = 'See Offers %%PATTERN:zip%%';

export function IncentivesSiteServedAdUI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  creativeConfig,
  customTargeting,
  mobile,
  position,
  cardWrapper,
  horizontalLayout,
  plainLayout,
  dropShadowLayout,
  showDropShadow,
  ads10773Recipe,
}) {
  const {
    siteServedAdsUpdater,
    zipCode,
    ipZipCode,
    offersNumber,
    location,
    creativeConfigData: {
      logo,
      linkDisclaimer,
      disclaimerCopy,
      headline,
      headline2,
      linkDisplayUrl,
      cta,
      lineItemId,
      siteServedCreativeId,
      target,
      isConquest,
    },
    traffickingData: { clickTracker },
  } = creativeConfig;

  const loadTrackingListener = useCallback(
    event => {
      const trackingValue = getTrackingValue(headline, offersNumber, zipCode, ipZipCode, DEFAULT_HEADLINE, 15, 15);
      adLoadTrackingListener(event, trackingValue);
    },
    [adLoadTrackingListener, headline, ipZipCode, offersNumber, zipCode]
  );

  if (!logo || !cta) {
    return null;
  }

  const displayHeadline = getDisplayHeadline(
    headline,
    offersNumber,
    zipCode,
    ipZipCode,
    DEFAULT_HEADLINE,
    15,
    15,
    location
  );

  const ctaURL = addZipCode(clickTracker[definePlatform(mobile)], zipCode);
  const ctaTarget = target || '_blank';
  const nativeStyle = INCENTIVES_AD.NATIVE_STYLE;

  const linkDisplay = (
    <div className="text-blue-40 text-center" style={{ ...Styles.mt_0_25, ...Styles.disclaimer }}>
      {linkDisplayUrl}
    </div>
  );

  const conquestRibbonStyle = { ...Styles.conquestRibbon, marginLeft: plainLayout ? '-16px' : 0 };
  const conquestRibbon = <ConquestRibbon style={conquestRibbonStyle} />;

  const isAds10773Enabled = ['chal1', 'chal2', 'chal3'].includes(ads10773Recipe);
  const isAds10773Stroke = ['chal1'].includes(ads10773Recipe);
  const isAds10773GrayBg = ['chal2', 'chal3'].includes(ads10773Recipe);

  const cardStyle = {
    ...(plainLayout ? { border: 'none' } : { overflow: 'hidden' }),
    ...(dropShadowLayout ? Styles.dropShadowLayout : {}),
    ...(showDropShadow ? Styles.showDropShadow : {}),
    ...(isAds10773GrayBg
      ? {
          marginLeft: '-1rem',
          marginRight: '-1rem',
          marginBottom: '-1rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingBottom: '1rem',
          border: '0',
        }
      : {}),
  };
  const containerStyles = dropShadowLayout ? Styles.px_1_25 : { ...(plainLayout ? {} : Styles.px_1_5) };
  const headlineStyles = dropShadowLayout ? Styles.headline_wired : Styles.headline;
  const ctaContainerStyles = {
    ...Styles.alignSelfStart,
    ...containerStyles,
    ...(horizontalLayout ? {} : Styles.mt_0_75),
    ...(linkDisplayUrl ? Styles.mb_0_5 : Styles.withoutLinkDisplayUrl[mobile]),
  };
  const ctaStyles = dropShadowLayout
    ? { ...Styles.fontWeight500, ...Styles.py_0_75 }
    : { ...Styles.fontWeightNormal, ...Styles.py_0_75 };

  return (
    <Card
      className={classnames('incentives-site-served pos-r', cardWrapper, {
        'mt-2 pt-1': isAds10773GrayBg,
        'w-100': !isAds10773GrayBg,
        'is-ads-10773-chal2': ads10773Recipe === 'chal2',
        'is-ads-10773-chal3': ads10773Recipe === 'chal3',
      })}
      data-tracking-parent={nativeStyle}
      data-viewability={`${nativeStyle}-${position}`}
      innerRef={adRef}
      style={cardStyle}
    >
      <DfpTracking
        lineItemId={lineItemId}
        siteServedCreativeId={siteServedCreativeId}
        mobile={mobile}
        nativeStyle={nativeStyle}
        position={position}
        customTargeting={customTargeting}
        slotRenderEndListener={loadTrackingListener}
        siteServedAdsUpdater={siteServedAdsUpdater}
      />
      {adTrackers}
      <a
        className="site-served-ad-link no-focus text-decoration-none d-block"
        href={ctaURL}
        target={ctaTarget}
        rel="noopener noreferrer"
        onClick={adClickTrackingListener}
      >
        {!plainLayout && <AdLabel styles={dropShadowLayout ? Styles.dropShadowAdLabel[mobile] : null} />}
        <div
          className={classnames('w-100', {
            'd-flex justify-content-between align-items-center': plainLayout,
          })}
          style={dropShadowLayout ? Styles.dropShadowOemLogo : Styles.my_0_25}
        >
          {isConquest ? (
            conquestRibbon
          ) : (
            <img
              className={classnames({ 'ms-0_5': !plainLayout, 'ms-1_25': dropShadowLayout })}
              style={Styles.oemLogo}
              alt=""
              src={logo}
              loading="lazy"
            />
          )}
          {plainLayout && <div style={{ fontSize: '10px', textAlign: 'right' }}>Sponsored</div>}
        </div>
        <div className={classnames('d-flex justify-content-between', { 'flex-column': !horizontalLayout })}>
          <div style={containerStyles}>
            <div
              className={classnames('headline fw-bold text-cool-gray-10 mb-0_75', {
                'mt-1': isAds10773Enabled,
              })}
              style={{ ...Styles.mb_0_25, ...(mobile ? Styles.headline_mobile : headlineStyles) }}
            >
              <ContentFragment>{displayHeadline}</ContentFragment>
            </div>
            <div
              className="small text-gray-darker"
              style={dropShadowLayout ? Styles.dropShadowHeadline2 : Styles.headline2}
            >
              <ContentFragment>{headline2}</ContentFragment>
            </div>
          </div>
          {isAds10773Enabled ? (
            <div className="w-100">
              <div
                className={classnames('btn text-nowrap text-transform-none size-16 px-0 pt-0_75 pb-0', {
                  'text-blue-50': isAds10773Stroke,
                  'text-blue-30': isAds10773GrayBg,
                })}
                style={ctaStyles}
              >
                <CtaButtonText text={cta} />
              </div>
            </div>
          ) : (
            <div className={classnames({ 'w-100': plainLayout })} style={ctaContainerStyles}>
              <div
                className={classnames('btn text-nowrap text-transform-none size-16', {
                  'py-0_5 px-0_75': !dropShadowLayout,
                  'py-0_75 px-1': dropShadowLayout,
                  'btn-blue-50': !plainLayout && !isConquest,
                  'btn-outline-blue-50': !plainLayout && isConquest,
                  'btn-outline-blue-50 w-100': plainLayout,
                })}
                style={ctaStyles}
              >
                <CtaButtonText text={cta} />
              </div>
              {!isAds10773Enabled && linkDisplay}
            </div>
          )}
        </div>
      </a>
      {disclaimerCopy && <Disclaimer linkDisclaimer={linkDisclaimer} disclaimerText={disclaimerCopy} />}
    </Card>
  );
}

IncentivesSiteServedAdUI.propTypes = {
  ...SiteServedAdPropTypes,
  customTargeting: CommonAdPropTypes.customTargeting,
  creativeConfig: IncentivesCreativeConfigPropTypes,
  cardWrapper: PropTypes.string,
  horizontalLayout: PropTypes.bool,
  plainLayout: PropTypes.bool,
  dropShadowLayout: PropTypes.bool,
  showDropShadow: PropTypes.bool,
  vehicle: PropTypes.shape({}),
  ads10773Recipe: PropTypes.string,
};

IncentivesSiteServedAdUI.defaultProps = {
  ...SiteServedAdDefaultProps,
  customTargeting: CommonAdDefaultProps.customTargeting,
  creativeConfig: IncentivesCreativeConfigDefaultProps,
  cardWrapper: null,
  horizontalLayout: false,
  plainLayout: false,
  dropShadowLayout: false,
  showDropShadow: false,
  vehicle: {},
  ads10773Recipe: null,
};

export const IncentivesSiteServedAd = siteServedAdTrackingWrapper(IncentivesSiteServedAdUI);
