export const Styles = {
  oemLogo: {
    maxHeight: '24px',
  },
  conquestRibbon: {
    height: '20px',
  },
  headline: {
    fontSize: '1.3125rem',
    lineHeight: '1.75rem',
    letterSpacing: '0',
  },
  headline_wired: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    letterSpacing: '0',
  },
  headlineAds10735: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    letterSpacing: '0',
  },
  headline_mobile: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    letterSpacing: 0,
  },
  headline2: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  fontWeightNormal: {
    fontWeight: 'normal',
  },
  fontWeight500: {
    fontWeight: '500',
  },
  disclaimer: {
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    letterSpacing: '0.024rem',
  },
  alignSelfStart: {
    alignSelf: 'flex-start',
  },
  px_0_75: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  px_1_5: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  px_1_25: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  py_0_75: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  mt_0_25: {
    marginTop: '4px',
  },
  mb_0_25: {
    marginBottom: '4px',
  },
  my_0_25: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  mt_0_75: {
    marginTop: '12px',
  },
  mb_0_5: {
    marginBottom: '8px',
  },
  mr_0_5: {
    marginRight: '8px',
  },
  mb_0: {
    marginBottom: '0',
  },
  withoutLinkDisplayUrl: {
    true: {
      marginBottom: '12px',
    },
    false: {
      marginBottom: '24px',
    },
  },
  dropShadowLayout: {
    border: 'none',
    paddingTop: '16px',
    background: '#F8FAFC',
    borderRadius: '8px',
  },
  showDropShadow: {
    boxShadow: '0px 4px 4px 0px #00000026',
  },
  dropShadowOemLogo: {
    marginTop: '4px',
    marginBottom: '16px',
  },
  dropShadowHeadline2: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
  },
  dropShadowAdLabel: {
    true: { top: '16px', right: '20px' },
    false: { top: '20px', right: '20px' },
  },
};
